
import {onMounted, reactive, ref, watch} from "vue";
import {ElLoading} from 'element-plus'
import {Modal} from "bootstrap";
import {listAnexos, deleteAnexo, store, tipoAnexo} from "@/services/HistoricoChecklistService";
import useAlert from "@/composables/Alert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {ElMessage} from 'element-plus';

export default {
  props: {
    checklist: String,
    codCheckList: String,
    updateListAnexos: Boolean
  },
  setup(prop) {
    const modalAnexos = ref()
    const tableData = ref()
    const payload = {
      checklist: 0,
      codChecklist: 0,
    }
    const button = ref(false)
    const loading = ref(false)
    const {showTimeAlert} = useAlert();
    const rules = ref()
    const ruleForm = reactive({
      obs: ''
    })
    const upload = ref()
    const buttonLoading = ref(true)
    const buttonAdicionar = ref(true)

    onMounted(() => {
      const modalAnexosID = document.getElementById('modal-anexos');
      modalAnexos.value = new Modal(modalAnexosID);
    })

    watch(() => [prop.checklist, prop.codCheckList], () => {
      payload.checklist = prop.checklist
      payload.codChecklist = prop.codCheckList
      getAnexos()
      tipoAnexoSelect()
    })

    // para quando clicar em anexos sempre dar um update na lista
    watch(() => prop.updateListAnexos, () => {
      upload.value.clearFiles()
      getAnexos()
      buttonAdicionar.value = true;
    })

    const getAnexos = async () => {
      loading.value = true

      const response = await listAnexos(payload.checklist, payload.codChecklist);
      tableData.value = response.anexos; // Atribua os dados � propriedade value da vari�vel ref
      
      loading.value = false
    };

    const tiposAnexo = ref();

    const tipoAnexoSelect = async () => {
        const responseTipoAnexo = await tipoAnexo(prop.checklist);

        tiposAnexo.value = responseTipoAnexo;
    }

    const deleteA = async (codAnexo: number) => {
      Swal.fire({
        title: "Deseja excluir esse anexo?",
        text: "Essa alteração não poderá ser desfeita!",
        showCancelButton: true,
        confirmButtonText: "Excluir anexo",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true
          await deleteAnexo(codAnexo).catch(async () => {
            showTimeAlert("Não foi possível completar a solicitação", "error")
            await getAnexos()
          }).catch(() => {
            loading.value = false
          })
          await getAnexos()
        }
      });

    }

    //caso precise usar um form
    const submitForm = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          // submitUpload()
        } else {
          button.value = false
        }
      })
    }
    const handleExceed = async (files) => {
      upload.value.clearFiles();
      const file = files[0];
      upload.value.handleStart(file);
    };

    const submitUpload = async () => {
      const fileList = upload.value.uploadFiles;
      if (fileList.length > 0) {
        buttonAdicionar.value = false;

        const file = fileList[0].raw;
        const fileType = file.type;

        if (fileType !== 'application/pdf') {
          showTimeAlert('Por favor, selecione um arquivo PDF.', 'error');
          return;
        }

        const base64String = await readFileAsDataURL(file);
        const payloadData = {
          pdf: base64String,
          obs: ruleForm.obs,
        }

        buttonLoading.value = false
        await store(payload.checklist, payload.codChecklist, payloadData).then(async () => {

          ruleForm.obs = ''
          await getAnexos()
          buttonLoading.value = true
          upload.value.clearFiles()
        }).catch(async ()=> {

          showTimeAlert("Não foi possível completar a solicitação", "error")
          await getAnexos()
          upload.value.clearFiles()
          buttonLoading.value = true
          ruleForm.obs = ''
        })
      }else{
        showTimeAlert('Por favor, selecione um arquivo.', 'error');
      }
    };

    //trasforma o pdf em Blob
    const readFileAsDataURL = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = () => {
          reject(new Error('Erro ao ler o arquivo.'));
        };

        reader.readAsDataURL(file);
      });
    };

    const onSuccess = (response, file, fileList) => {
      // Lida com o sucesso do upload
    };

    //identificar que tem um arquivo selecionado
    const handleFile = (response, file, fileList) => {
      buttonAdicionar.value = false
    }

    return {
      modalAnexos,
      tableData,
      deleteA,
      loading,
      ruleForm,
      rules,
      submitUpload,
      button,
      upload,
      handleExceed,
      buttonLoading,
      buttonAdicionar,
      handleFile,
      tiposAnexo
    }
  }
}
